// custom-bootstrap.scss

// Import Bootstrap's functions (required for variable overrides)
@import "node_modules/bootstrap/scss/functions";


$primary: rgb(135, 189, 61);
$secondary: rgb(135, 189, 61);

// Optionally, update the theme colors map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  // Add other colors if needed
);

// Import Bootstrap's variables and the rest of Bootstrap
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/bootstrap";