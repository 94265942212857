/* General body styling */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa; /* Light gray background for a softer look */
  color: #333;
}

/* Code styling */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Container styling */
.container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.header-container {
  max-width: 1200px;
  margin: 0 auto;
}

.menu-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
}

.menu-icon {
  display: block;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  overflow-y: auto;
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.sidebar.open {
  left: 0;
}

.sidebar nav a {
  color: white;
  text-decoration: none;
  padding: 10px 0;
  display: block;
}

.close-sidebar {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .menu-toggle {
    display: none;
  }

  .sidebar {
    display: none;
  }
}

.image-spacing{
  margin-left: 10px;
}

.image-wrapper {
  position: relative;
  display: inline-block;
}

.shadowing {
  z-index: 2;
  position:relative
}

.image-wrapper::after {
  content: '';
  position: absolute;
  left: 15px; /* Adjust offset */
  width: 40px; /* Match the image width */
  height: 40px; /* Match the image height */
  background: rgba(0, 0, 0, 0.1); /* Shadow color */
  border-radius: 50%; /* Match the image shape */
  z-index: 1;
  filter:blur(5px);
}

.icon-container {
  font-size: 2.5rem; /* Set icon size */
  height: 3rem; /* Consistent height for all icons */
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Card styling */
.card {
  border-radius: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  overflow: hidden;
  background-color: #ffffff; /* White background for cards */
  transition: transform 0.2s ease-in-out;
}

/* Card hover effect */
.card:hover {
  transform: translateY(-5px); /* Lift card slightly on hover */
}

.card:hover .blurred-background{
  transform: translateY(-5px); /* Lift card slightly on hover */
}

/* Card title styling */
.card-title {
  font-weight: 600;
  font-size: 1.8rem;
  color: #007bff; /* Slightly accent color */
}

/* Content paragraph styling within card */
.card-body .content p {
  line-height: 1.6;
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 1rem;
}

/* Placeholder styling for images if not available */
.card-img-placeholder {
  height: 200px;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
}

/* Scrollbar Styles for WebKit Browsers */
.card-body::-webkit-scrollbar {
  width: 8px;
}

.card-body::-webkit-scrollbar-track {
  background: transparent;
}

.card-body::-webkit-scrollbar-thumb {
  background-color: #888; /* Change to your desired color */
  border-radius: 10px;
  border: 2px solid transparent;
}

.card-body::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

/* Scrollbar Styles for Firefox */
.card-body {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
}

/* Card body padding */
.card-body {
  padding: 20px;
}
